





import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';

@Component({
    components: {
        LoaderWrapper,
        CustomLoader,
    },
})
export default class AuthPage extends Vue {
    @Inject(AuthServiceS) private authService!: AuthService;

    @Prop({ required: false })
    private token?: string;

    async mounted() {
        if (this.token) {
            await this.authService.authentication(this.token);
            this.$router.push('/');
        } else {
            window.location.href = this.authService.loginUrl;
        }
    }

    get isCarPage() {
        return /cr/.test(window.location.hostname);
    }
}
